import React, {createContext, useEffect, useReducer} from "react";

// import spin from "../assets/images/decojent-loader.gif";

export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();


const initialState = {
    Loader: true
}

function reducer(state, action){
    switch (action.type) {
        case 'false' : {
            return {
                ...state,
                Loader: state.Loader === false
            }
        }    
        default:
            throw Error("Bad Action")
    }
}

const Prelaoder = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        state.Loader = false;
    })
    
    return(
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
}


export default  Prelaoder;