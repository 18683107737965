import React from "react";
import Prelaoder from "./src/Context/peloader";

export  const wrapRootElement = ({ element }) => {
    
    return (<Prelaoder>{element}</Prelaoder>) 
}

// export function shouldUpdateScroll(prevRouterProps, { location }) {
//     window.scrollTo(0, 0)
//     const body = document.getElementsByTagName('body')[0]
//     body.scrollTop = 0
//     return false
// }

// exports.onRouteUpdate = () => {
//     if (window.APESTER) {
//        window.APESTER.reload();
//     } 
// }


