module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"34e48b7e0e181834cf915172d454ce90"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://backend.decojent.com/graphql","baseUrl":"backend.decojent.com","protocol":"https","restApiRoutePrefix":"wp-json","hostingWPCOM":false,"useACF":true,"acfOptionPageIds":[],"cookies":{},"verboseOutput":false,"perPage":100,"concurrentRequests":200,"includedRoutes":["**/categories","**/posts","**/pages","**/media","**/tags","**/taxonomies","**/users"],"excludedRoutes":["**/posts/1456"],"keepMediaSizes":false,"verbose":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
